import React from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"
import Modal from "./modal/modal"
import { Col, Container, Row } from "react-bootstrap"
import PartnerSwiper from "./swiper/swiper-partner.js"
import FreeIntegrationMappingImg from "../images/new-images/1.jpg"
import { GiGears, GiHeartInside, GiLighthouse, GiPuzzle } from "react-icons/gi"
import { RiDragDropFill } from "react-icons/ri"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { StaticImage } from "gatsby-plugin-image"
import Cases from "./cases"
import Testimonials2 from "./swiper/testimonial-swiper"

const WorkatoSections = ({ props }) => {
  return (
    <>
      <PartnerSwiper />
      <section
        id="status"
        className="stat-wrapper"
        style={{
          padding: "3rem 0 0",
        }}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-10 mx-auto mb-5">
              <h2 className="font-weight-medium">
                Inefficient Workflows and Processes Kill Productivity
              </h2>
            </div>
            <div className="col-lg-4 col-md-4 stat-box-col">
              <div className="stat-box box-1 mx-auto">
                <h3 className="font-weight-bold mb-5">9%</h3>
              </div>
              <p className="text-uppercase my-4" style={{ color: "red" }}>
                of companies have a complete, fully-utilized MarTech stack.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 stat-box-col">
              <div className="stat-box box-2 mx-auto">
                <h3 className="font-weight-bold mb-5">40%</h3>
              </div>
              <p className="text-uppercase my-4" style={{ color: "#00b5f4" }}>
                of productivity is lost to task-switching.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 stat-box-col">
              <div className="stat-box box-3 mx-auto">
                <h3 className="font-weight-bold mb-5">40%</h3>
              </div>
              <p className="text-uppercase my-4" style={{ color: "#091031" }}>
                of employee time goes to finding documents manually.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div
            className="row"
            style={{
              padding: "3rem 0",
            }}
          >
            <div className="col-lg-6">
              <h2 className="mb-4">
                Each New App Complicates and Fragments Your Business
              </h2>
              <p>
                Workato bridges those gaps with simple system integrations that
                easily connect your most powerful applications. Using
                spreadsheets to capture and manually transfer data between
                systems isn't scalable. That path leads to time-wasting
                bottlenecks, unnecessary risk, and systems that slow your
                growth.
              </p>
              <p>
                Workato offers a seamless solution. Drag and drop custom
                integrations to effortlessly automate your most complex
                processes. It's system automation that scales securely with the
                cost and time associate with traditional custom builds.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-lg-0 mt-md-5 mt-5">
              <LazyLoadComponent>
                <iframe
                  width="100%"
                  height="380px"
                  src="https://www.youtube.com/embed/u8gFwj_e1KI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                  title="Tech Consulting"
                  className="rounded"
                ></iframe>
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </section>
      <section id="case-studies-container">
        <div id="case-studies" className="bg-indigo">
          <div className="container">
            <div className="intro text-center">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                    Case Studies
                  </h5>
                  <h2 className=" my-2 text-white">
                    We Save Clients 1000s of Hours. Every Year.
                  </h2>
                  {/* <p className="text-white">
                    We helped a national healthcare provider save over 9,000
                    hours in weekly labor costs by developing a single
                    application...
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <Cases allSanityCaseStudy={props.data.allSanityCaseStudy} />
        </div>
      </section>
      <section
        className="quick-base-glance"
        style={{
          padding: "3rem 0",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-center mb-5">
                Automate Your Entire Business with Workato
              </h2>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <RiDragDropFill />
              <h3 className="my-4">
                Drag and Drop
                <br />
                Interface
              </h3>
              <p>Create custom, automated workflows without learning code.</p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <GiPuzzle />
              <h3 className="my-4">
                Connect Your
                <br />
                Application Stack
              </h3>
              <p>With a few clicks, access 1000s of premade integrations.</p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <GiLighthouse />
              <h3 className="my-4">
                Increase Visibility
                <br />
                and Efficiency
              </h3>
              <p>
                Connect disparate systems and applications for seamless data
                transfer.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center ml-lg-auto">
              <GiGears />
              <h3 className="my-4">
                Automate Workflows
                <br />
                and Processes
              </h3>
              <p>
                Connect business applications with secure integrations that
                streamline work.
              </p>
            </div>
            <div className="col-lg-4 mt-5 text-center mr-lg-auto">
              <GiHeartInside />
              <h3 className="my-4">
                Make Work
                <br />
                Meaningful
              </h3>
              <p>Remove menial, boring tasks and endless spreadsheets.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-md-5" style={{ padding: "3rem 0 3rem 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="mb-1">
                Transform Your Business with Powerful Automations
              </h2>
            </div>
          </div>
          <div
            className="row align-items-center mt-lg-5 mt-md-0 mt-5 flex-lg-row flex-md-row flex-column-reverse"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
              marginTop: "3rem",
            }}
          >
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
              <h3>Workato Is Simple to Use</h3>
              <p className="mt-4">
                Choose the apps you want to connect, set triggers, and add
                multiple actions. Once you drag and drop everything in place,
                your automations are ready to go.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 py-lg-3 py-3">
              <StaticImage
                placeholder="blurred"
                imgClassName="rounded d-flex w-100 img-fluid"
                src="../images/new-images/workato-images/workato-is-simple.png"
                alt="Workato Is Simple to Use"
                layout="constrained"
                objectFit="contain"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-lg-6 col-md-6 py-lg-4 py-3">
              <StaticImage
                placeholder="blurred"
                imgClassName="rounded d-flex w-100 img-fluid"
                src="../images/new-images/workato-images/connect-over-300+.png"
                alt="Connect Over 300+ Enterprise Apps"
                layout="constrained"
                objectFit="contain"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
              <h3>Connect Over 300+ Enterprise Apps</h3>
              <p className="mt-4">
                With new app connections added frequently, you can connect your
                most valuable systems with ease: Salesforce, Netsuite, Marketo,
                and more!
              </p>
            </div>
          </div>
          <div
            className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
          >
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
              <h3>
                Complete Control Over
                <br />
                Your Processes
              </h3>
              <p className="mt-4">
                Start automations with a single click. Pause any time to update
                flows. And start back up again without losing data or events.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 py-lg-3 py-3">
              <StaticImage
                placeholder="blurred"
                imgClassName="rounded d-flex w-100"
                src="../images/new-images/workato-images/complete-control.png"
                alt="Complete Control Over Your Processes"
                layout="constrained"
                objectFit="contain"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <LazyLoadComponent>
        <Testimonials2 />
      </LazyLoadComponent>
      <section>
        <div
          id="quick-base-free-trial"
          style={{ backgroundImage: `url(${FreeIntegrationMappingImg})` }}
        >
          <div className="bg-light-blue text-center">
            <Container>
              <p className="text-white m-0 py-2 text-18">
                <span className="font-weight-bold">
                  HAVE QUESTIONS BEFORE SIGNING UP?
                </span>{" "}
                Give Us A Call At{" "}
                <a
                  aria-label="link"
                  href="tel:+17207391406"
                  onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '720-739-1406', 0);"
                  className="text-white"
                >
                  (720) 739-1406.
                </a>
              </p>
            </Container>
          </div>
          <section
            className="quick-base-trial-banner"
            style={{
              padding: "3rem 0",
            }}
          >
            <Container>
              <Row>
                <Col md={`${11} mx-auto`}>
                  <div className="content text-center">
                    <StaticImage
                      placeholder="blurred"
                      imgClassName="mb-3"
                      src="../../static/assets/footer-logo.png"
                      alt="Quandary Logo White"
                      layout="constrained"
                      loading="lazy"
                    />
                    <h4 className="text-white mt-3">
                      Connect All Your Business Systems
                    </h4>
                    <hr className="bg-light" />
                    <h1 className="text-light-blue">
                      Free Integration Mapping Session
                    </h1>
                    <hr className="bg-light" />

                    <p className="my-3 text-18 text-white">
                      Break free from spreadsheets, wasting time hunting down
                      documents, and endless messages that ruin productivity.
                    </p>
                    <p className="my-3 text-18 text-white">
                      Book now and you’ll get up to 5 hours of free consulting
                      to help you map out your systems.
                    </p>

                    <div className="d-flex justify-content-center mt-4">
                      <Modal
                        modalSize="md"
                        modalClass="modal-signup"
                        // btnClass="btn btn-signup"
                        btnClass="btn workato-free-signup"
                        html={
                          <div className="text-white">
                            <i
                              className="fa fa-check-square-o text-white"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;Get Free Integration Mapping
                          </div>
                        }
                      >
                        <div id="signupForm" className="px-4 pb-4">
                          <div className="content text-center">
                            <StaticImage
                              placeholder="blurred"
                              src="../images/QCG-Primary-logo-_1_.png"
                              alt="Quandary logo"
                              layout="constrained"
                              style={{ width: 250 }}
                              loading="lazy"
                            />
                            <div className="bg-light-blue p-2 my-4">
                              <h4 className="text-white m-0 font-weight-normal">
                                CLAIM YOUR FREE INTEGRATION MAPPING SESSION!
                              </h4>
                            </div>
                            <p className="text-dark">
                              Enter your name and email address below to claim
                              your <br />
                              <span className="font-weight-bold">
                                Free Integration Mapping Session TODAY! (A $500+
                                Value!)
                              </span>
                            </p>
                            <WebriQForm
                              id="freeInegration"
                              name="Free Integration Mapping Session Form"
                              className="signup-form"
                              data-form-id="5e32881866214828bdf46fe5"
                              data-thankyou-url="/thank-you"
                            >
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="First Name"
                                  className="form-control"
                                  placeholder="First Name"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="Last Name"
                                  className="form-control"
                                  placeholder="Last Name"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="Email"
                                  className="form-control"
                                  placeholder="Your Best Email Address"
                                  required
                                />
                              </div>
                              <div className="form-group mt-4">
                                <div className="webriq-recaptcha" />
                              </div>
                              <div className="form-group mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary free-animate animated infinite swing slow text-18 w-100"
                                >
                                  <i
                                    className="fa fa-check-square-o"
                                    aria-hidden="true"
                                  ></i>
                                  YES, SIGN ME UP!
                                </button>
                              </div>
                            </WebriQForm>
                          </div>
                        </div>
                      </Modal>
                    </div>

                    <div className="py-2 mt-4">
                      <p className="m-0 text-white px-3 text-18 font-italic">
                        $500 value. Yours when you sign up today!
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </section>
    </>
  )
}

export default WorkatoSections
