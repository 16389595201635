import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
// import MySwiper from "./swiper/swiper"
import Swiper from "react-id-swiper"
// import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
import { Pagination } from "swiper/js/swiper.esm"
import { reviewData } from "../../components/reviewData"
import "./testi-new.scss"
// import { FaUserCircle } from "react-icons/fa"
import { AiFillLinkedin } from "react-icons/ai"
import UserPlaceHolder from "../../images/new-images/testimonial-images/user.png"

const params = {
  modules: [Pagination],
  slidesPerView: 3, // Default number of slides for larger screens
  spaceBetween: 60, // Default space between slides for larger screens
  containerClass: "swiper-container pb-5 review-card-padding",
  nested: true,
  autoplay: false,
  pagination: {
    el: ".test-swiper-contact-pagination",
    type: "bullets",
    clickable: true,
  },
  breakpoints: {
    280: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 15, // Reduce space further
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },

    768: {
      slidesPerView: 2,
      spaceBetween: 30, // Reduce space between slides
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 50, // Reduce space between slides
    },
  },
}

function TestimonialSwiper() {
  const [expandedIndex, setExpandedIndex] = useState(null)

  const toggleExpansion = index => {
    setExpandedIndex(index === expandedIndex ? null : index)
  }

  return (
    <section className="intro bg-gradient" style={{ padding: "5rem 0" }} id="">
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-12">
            <p className="h5 text-light-blue text-center text-uppercase font-weight-bold m-0">
              Reviews
            </p>
            <h2 className="my-2 text-white mt-4 text-center">
              What Our Clients Say
            </h2>
          </div>
        </div>
      </div>
      <div className="new-testimonial">
        <Swiper {...params}>
          {reviewData.map((item, idx) => {
            return (
              <div className="testi-item">
                <div className="testimonials-grid">
                  <div className="testi-item-inner">
                    <div className="testimonial-content newContent">
                      <div className="text-left">
                        <p style={{ display: "inline" }}>
                          {expandedIndex === idx
                            ? item.review
                            : item.review.length > 360
                            ? `${item.review.slice(0, 360)}...`
                            : item.review}
                        </p>
                        {item.review.length > 360 && (
                          <button
                            className="read-more"
                            onClick={() => toggleExpansion(idx)}
                            style={{
                              display: "inline",
                              color: "#00B6B0",
                              background: "none",
                              border: "none",
                              cursor: "pointer",

                              padding: 0,
                            }}
                          >
                            {expandedIndex === idx ? "Read Less" : "Read More"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="testimonial-holder mt-4">
                      <div className="testimonial-meta row">
                        <div className="col-md-2 col-sm-3 col-2 pr-0">
                          {item.headShot ? (
                            <img
                              src={item?.headShot}
                              className="img-fluid rounded-circle "
                              alt={item?.reviewer}
                              style={{ width: "70px" }}
                            />
                          ) : (
                            <img
                              src={UserPlaceHolder}
                              className="img-fluid rounded-circle "
                              alt="placeholder image"
                              style={{ width: "70px" }}
                            />
                          )}
                        </div>
                        <div className="col-md-10 col-sm-9 col-10 text-left">
                          <p className="h5 title my-0">{item?.reviewer}</p>
                          <p
                            className="company mb-0"
                            style={{ color: "#8a49a8", fontSize: "13px" }}
                          >
                            {item?.company}
                          </p>
                          {item?.linkedLink && (
                            <Link
                              to={item?.linkedLink}
                              aria-label={`Follow ${item?.reviewer} on LinkedIn`}
                              target="_blank"
                            >
                              <AiFillLinkedin size={"30px"} />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Swiper>
      </div>
      <div className="text-center">
        <a
          className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
          href="https://www.Quickbase.com/partners/quandary-consulting-group"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="see more quandary reviews"
        >
          See More Quandary Reviews
        </a>
      </div>
    </section>
  )
}

export default TestimonialSwiper
